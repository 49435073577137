import './utils/vh.utils.js';

import './libs/countUp.lib.js';
import './libs/fancybox.lib.js';
import './libs/plyr.lib.js';
import './libs/telInput.lib.js';

import './components/uploadFile.component.js';
import './components/inputs.component.js';
import './components/achievements.component.js';
import './components/glow.component.js';
import './components/sliders.component.js';
import './components/popups.component.js';
import './components/header.component.js';
import './components/hero.component.js';
import './components/blog.component.js';
import './components/thank-you.component.js';
import './components/forms/contacts.component.js';
import './components/forms/start-education.component.js';
import './components/forms/learning-request.component.js';
import './components/forms/scalping-info.component.js';
import './components/forms/call-me.component.js';
import './components/forms.component.js';
import './components/imgs.component.js';
